// app/javascript/controllers/rating_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "label"];

  connect() {
    this.updateStars();
  }

  select(event) {
    const selectedRating = event.currentTarget.dataset.value;
    this.inputTarget.value = selectedRating;
    this.updateStars(selectedRating);
  }

  updateStars(selectedRating = this.inputTarget.value) {
    this.labelTargets.forEach((label, index) => {
      if (index < selectedRating) {
        label.classList.add("text-yellow-400");
        label.classList.remove("text-gray-300");
      } else {
        label.classList.add("text-gray-300");
        label.classList.remove("text-yellow-400");
      }
    });
  }
}
